import React, { useMemo } from "react"

import Layout from "../../components/Layout"
import TextBlock from "../../components/TextBlock/TextBlock"
import TitleBlock from "../../components/TitleBlock"
import OffsetAnchor from "../../components/OffsetAnchor"
import HorLine from "../../components/HorLine"

import "../../styles/global.scss"
import stylesSolutions from "./solutions.module.scss"
import SolutionsBlockImg from "../../assets/images/pages/home/SolutionsBlockImg.svg"
import { remark } from "remark"
import remarkHtml from "remark-html"
import { graphql, useStaticQuery } from "gatsby"
import createHtml from "../../helpers/createHtml"
import findLangIndex from "../../helpers/findLangIndex";
import useGetBrowserLanguage from "../../hooks/useGetBrowserLanguage"

const renderTraidingBlocks = item => {
  return (
    <div className={stylesSolutions.trainingBlock__listBlock__item}>
      <OffsetAnchor anchorId={item?.text.toLowerCase()} />
      <img src={item?.image} alt="" />
      <div className={stylesSolutions.trainingBlock__listBlock__item__title}>{item?.title}</div>
      <div>
        <div
          className={stylesSolutions["trading-block__wrapper"]}
          dangerouslySetInnerHTML={createHtml(item?.short_text)}
        />
      </div>
    </div>
  )
}

const Solutions = () => {
  const language = useGetBrowserLanguage()

  const { allMarkdownRemark } = useStaticQuery(graphql`
    query SolutionsPageData {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(solutions.md)/" } }
      ) {
        edges {
          node {
            fileAbsolutePath
            frontmatter {
              mini_title
              title
              title_description
              section_description
              technologies {
                image
                title
                slug
                text
                short_text
              }
            }
          }
        }
      }
    }
  `)

  const remarkObj = useMemo(() => remark().use(remarkHtml), [])

  const pageData = useMemo(() => {
    const langIdxEn = findLangIndex(allMarkdownRemark?.edges, "solutions/solutions.md", "en");

    return {
      en: {
        miniTitle: allMarkdownRemark?.edges?.[langIdxEn]?.node?.frontmatter?.mini_title,
        title: allMarkdownRemark?.edges?.[langIdxEn]?.node?.frontmatter?.title,
        titleDescription: remarkObj
          .processSync(
            allMarkdownRemark?.edges?.[langIdxEn]?.node?.frontmatter?.title_description
          )
          .toString(),
        sectionDescription: remarkObj
          .processSync(
            allMarkdownRemark?.edges?.[langIdxEn]?.node?.frontmatter
              ?.section_description
          )
          .toString(),
        techs:
          allMarkdownRemark?.edges?.[langIdxEn]?.node.frontmatter?.technologies?.map(
            item => {
              return {
                image: item?.image,
                text: remarkObj.processSync(item?.text).toString(),
                short_text: remarkObj.processSync(item?.short_text).toString(),
                title: item?.title,
              }
            }
          ),
      },
    }
  }, [remarkObj, allMarkdownRemark])

  return (
    <Layout>
      <div className={stylesSolutions.mainBoxTop}>
        <TitleBlock
          className={stylesSolutions.titleBlock}  
          miniTitle={pageData?.en?.miniTitle}
          title={pageData?.en?.title}
          image={
            <SolutionsBlockImg className={stylesSolutions.titleBlock__mainImg} />
          }
          markdowndData={pageData?.en?.titleDescription}
        />
      </div>
      <div className={stylesSolutions.mainBoxBottom}>
        <div className={stylesSolutions.trainingBlock__listBlock}>
          {pageData?.en?.techs?.map(item => renderTraidingBlocks(item))}
        </div>
      </div>
    </Layout>
  )
}

export default Solutions
